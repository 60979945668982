import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import {
  getCurrentWalletConnected,
  connectWallet,
  mintNFT
} from "./interact.js";
import React from 'react';

const App = () => {
  const [walletAddress, setWallet] = useState("");
  let currentQuantity = 1;

  useEffect(() => {
    async function fetchData() {
      if (window.ethereum) {
        const { address } = await getCurrentWalletConnected();
        setWallet(address);

        addWalletListener();
        connectWalletPressed();
      }
    }
    fetchData();
  }, []);

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
        }
      });
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(walletResponse.address);
  };

  function updateQuantityBy(number) {
    const quantityText = document.querySelector(".quantity-text");
    if ((currentQuantity === 1 && number === -1) || (currentQuantity === 200 && number === 1)) {
      return;
    }

    currentQuantity += number;
    quantityText.innerHTML = currentQuantity;

    const mintContainer = document.querySelector(".mint-container");
    mintContainer.dataset.currentQuantity = currentQuantity;
  }

  const onMinusPressed = async () => {
    updateQuantityBy(-1);
  };

  const onPlusPressed = async () => {
    updateQuantityBy(1);
  };

  const onMintPressed = async () => {
    const mintContainer = document.querySelector(".mint-container");
    const swipeContainer = document.querySelector(".swiper");
    const quantity = Number(mintContainer.dataset.currentQuantity);
    const price = Number(swipeContainer.dataset.currentPrice);

    const {status} = await mintNFT(quantity, price);
    const statusEvent = new CustomEvent('status', {detail: status});
    document.dispatchEvent(statusEvent);
  };

  if (!window.ethereum) {
    if (isMobile) {
      return (
        <div className="mint-container">
          <button className="mint-button bg-gradient-purple akira open" onClick={() => {
            window.open("https://metamask.app.link/dapp/mint.meta-legends.com");
          }}>
            OPEN METAMASK
          </button>
        </div>
      );
    }

    return (
      <div className="mint-container">
        <button className="mint-button bg-gradient-purple akira install" onClick={() => {
          window.open("https://metamask.io/download.html");
        }}>
          INSTALL METAMASK
        </button>
      </div>
    );
  }

  return (
		<div className="mint-container" data-current-quantity="1">
			{walletAddress.length > 0 ? (
				<button
					className="mint-button bg-gradient-purple akira"
					onClick={onMintPressed}
				>
					MINT
				</button>
			) : (
				<button
					className="mint-button bg-gradient-purple akira connect"
					onClick={connectWalletPressed}
				>
					CONNECT METAMASK
				</button>
			)}
			<div className="quantity">
				<button className="quantity-button" onClick={onMinusPressed}>
					<img src="/assets/images/minus.svg" alt="Minus" />
				</button>
				<div className="quantity-text bg-gradient-purple akira">1</div>
				<button className="quantity-button" onClick={onPlusPressed}>
					<img src="/assets/images/plus.svg" alt="Plus" />
				</button>
			</div>
		</div>
	);
};

export default App;
