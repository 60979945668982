const contractABI = require("./contract-abi.json");
const contractAddress = process.env.REACT_APP_CONTRACT_ID;
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const connectWallet = async () => {
  if (!window.ethereum) {
    return;
  }

  try {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const obj = {
      address: addressArray[0],
    };

    return obj;
  } catch (err) {
    return {
      address: ""
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (!window.ethereum) {
    return;
  }

  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (addressArray.length > 0) {
      return {
        address: addressArray[0],
      };
    } else {
      return {
        address: ""
      };
    }
  } catch (err) {
    return {
      address: ""
    };
  }
};

export const currentMinted = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  // let total = await window.contract.methods.maxSupply().call();
  let supply = await window.contract.methods.totalSupply().call();

  return {
    actualTokens: Number(supply),
    maxTokens: Number(12345)
  }
}

export const mintNFT = async (quantity, price) => {
  if (![0.3, 0.5, 1, 1.5, 2, 2.5].includes(price)) {
    return {
      status:
        "❗ Wrong price selected, please try again using 0.3, 0.5, 1, 1.5, 2 or 2.5 ETH",
    };
  }

  if (!quantity || quantity <= 0 || quantity > 200) {
    return {
      status: "❗ You can only mint 1 to 200 Meta Legends per transaction",
    };
  }

  const chainId = await window.ethereum.request({method: "eth_chainId"});

  // TODO : A réactiver avant la MEP
  if (chainId !== '0x1') {
    return {
      status: "❗ You need to select Ethereum Mainnet Network in Metamask to mint Meta Legends",
    };
  }

  let walletAddress = null;

  if (window.ethereum.selectedAddress) {
    walletAddress = window.ethereum.selectedAddress;
  } else {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      walletAddress = addressArray[0];
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  }

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  let total = price * 10 ** 18 * quantity;
  let mint = window.contract.methods.publicSaleMint(quantity).encodeABI();

  const transactionParameters = {
    from: walletAddress,
    to: contractAddress,
    value: web3.utils.toHex(total),
    data: mint,
  };

  try {
    await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });

    window.gtag("event", "Mint");
    window.fbq("track", "Purchase");

    return {
      status: "✅ Congrats! You have minted " + quantity + " Meta Legends 👻",
    };
  } catch (error) {
    return {
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
