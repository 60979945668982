import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import {
  connectWallet,
  getCurrentWalletConnected
} from "./interact.js";
import React from 'react';

const Connect = () => {
  const [walletAddress, setWallet] = useState("");

  useEffect(() => {
    async function fetchData() {
      if (window.ethereum) {
        const { address } = await getCurrentWalletConnected();
        setWallet(address);

        addWalletListener();
        connectWalletPressed();
      }
    }
    fetchData();
  }, []);

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
        }
      });
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(walletResponse.address);
  };

  if (!window.ethereum) {
    if (isMobile) {
      return (
        <button id="connect" className="bg-gradient-purple" onClick={() => {
          window.open("https://metamask.app.link/dapp/mint.meta-legends.com");
        }}>
          <span className="wallet bronova">OPEN METAMASK</span>
          <div className="connection-state red"></div>
        </button>
      );
    }

    return (
      <button id="connect" className="bg-gradient-purple" onClick={() => {
        window.open("https://metamask.io/download.html");
      }}>
        <span className="wallet bronova">INSTALL METAMASK</span>
        <div className="connection-state red"></div>
      </button>
    );
  }

  return (
    <span>
      {walletAddress.length > 0 ? (
        <button id="connect" className="bg-gradient-purple">
          <span className="wallet wallet-connected bronova">{walletAddress}</span>
          <div className="connection-state green"></div>
        </button>
      ) : (
        <button id="connect" className="bg-gradient-purple" onClick={connectWalletPressed}>
          <span className="wallet bronova">CONNECT WALLET</span>
          <div className="connection-state red"></div>
        </button>
      )}
    </span>
  );
};

export default Connect;
