import { useEffect, useState } from "react";
import {
  currentMinted
} from "./interact.js";
import React from 'react';

const Supply = () => {
  const [actualTokens, setActualTokens] = useState("0");
  const [maxTokens, setMaxTokens] = useState("1 100");

  useEffect(() => {
    async function fetchData() {
      checkCurrentMinted();

      setInterval(() => {
        checkCurrentMinted();
      }, 30000);
    }

    fetchData();
  });

  const checkCurrentMinted = async () => {
    const { actualTokens, maxTokens } = await currentMinted();
    const progressBar = document.querySelector(".progress-bar");
    const percentage = (actualTokens * 100) / maxTokens;

    setActualTokens(actualTokens.toLocaleString());
    setMaxTokens(maxTokens.toLocaleString());

    progressBar.style.width = percentage + '%';
  }

  return (
    <div className="supply">
      <span className="bronova">Legends already minted : <span id="currentSupply">{actualTokens}</span> / <span
        id="totalSupply">{maxTokens}</span></span>
      <div className="progress-container">
        <div className="progress-bar bg-gradient-purple"></div>
      </div>
    </div>
  );
};

export default Supply;
