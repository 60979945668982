import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from "react-countdown";
import App from './App';
import Connect from './Connect';
import Supply from './Supply';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('mint')
);

ReactDOM.render(
  <React.StrictMode>
    <Connect />
  </React.StrictMode>,
  document.getElementById('connection')
);

ReactDOM.render(
  <React.StrictMode>
    <Supply />
  </React.StrictMode>,
  document.getElementById('supply')
);

// ReactDOM.render(
// 	<Countdown date={1640905140000} />,
// 	document.getElementById("countdown")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
